import axios from 'axios';

const AhmadRClient = axios.create({
	baseURL: 'https://api.webdisdik.jabarprov.go.id/api-ppdb',
	timeout: 8000,
	headers: {
		Accept: 'application/json'
	},
});

export default AhmadRClient;