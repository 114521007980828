import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import AhmadRClient from './services/api';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
  ButtonToolbar,
} from "react-bootstrap";

function App() {
  const query = new URLSearchParams(window.location.search);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowGagal, setModalShowGagal] = useState(false);
  const [dataHome, setDataHome] = useState(null);

  const addSurvey = async (data) => {
    data.preventDefault();

    const _data = {
      nama: data.target.name.value,
      email: data.target.email.value,
      alamat: data.target.alamat.value,
      recommend: data.target.recommend.value,
      comments: data.target.comments.value,
    }

    await AhmadRClient.post('/v1/kepuasan', _data)
      .then((res) => {
        setModalShow(true);
      })
      .catch((err) => {
        console.log(err);
        setModalShowGagal(true);
      });
  }

  return (<>
    <Container className='my-container'>
      <div className="c-container">
        <div className="header">
          <h1 id="title" style={{ fontWeight: 'bold' }}>Penilaian Kepuasan Layanan</h1>
          <p id="description">Tolong beritahu kami apa yang anda pikirkan tentang pelayanan</p>
          <p>Informasi dan Pengaduan PPDB</p>
        </div>

        <form id="survey-form" onSubmit={addSurvey}>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Nama :</label>
            <input required name="name" type="text" className="form-control" id="exampleFormControlInput1" placeholder="Nama Anda" />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlInput2" className="form-label">Email / Nomor Telepon :</label>
            <input required name="email" type="email" className="form-control" id="exampleFormControlInput2" placeholder="Email atau Nomor Telepon Anda" />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">Alamat :</label>
            <textarea name="alamat" className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Tulis alamat kamu disini..."></textarea>
          </div>

          <div className="row mt-4">
            <p className="label">Bagaimana penilaian anda atas Kinerja Layanan Kami?</p>
          </div>

          <div className="small-row justify-sb">
            <div className="radio-control">
              <input type="radio" id="recommend-1" name="recommend" value="1" />
              <label htmlFor="recommend-1">Sangat<br />Memuaskan</label>
            </div>
            <div className="radio-control">
              <input type="radio" id="recommend-2" name="recommend" value="2" />
              <label htmlFor="recommend-2">Memuaskan</label>
            </div>
            <div className="radio-control">
              <input type="radio" id="recommend-3" name="recommend" value="3" />
              <label htmlFor="recommend-3">Tidak<br />Memuaskan</label>
            </div>
          </div>

          <div className="row  mt-4">
            <label htmlFor="comments">Apa ada komentar lainnya?</label>
          </div>

          <div className="row">
            <textarea name="comments" rows="6" id="comments" placeholder="Tulis komentarmu disini..."></textarea>
          </div>

          <div className="row">
            <button id="submit" type="submit">Submit</button>
          </div>


        </form>
      </div>

    </Container>
    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
    />

    <MyVerticallyCenteredModalGagal
      show={modalShowGagal}
      onHide={() => setModalShowGagal(false)}
    />
  </>
  );
}

export default App;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-black'
      onExit={() => window.location.reload()}
    >
      <Modal.Body>
        <h4>Terimakasih</h4>
        <p>
          Telah mengisi survey kepuasan layanan kami.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="success" className="text-white">Tutup</Button>
      </Modal.Footer>
    </Modal>
  );
}

function MyVerticallyCenteredModalGagal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-black'
    >
      <Modal.Body>
        <h4>Mohon Maaf</h4>
        <p>
          Telah terjadi kesalahan.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="success" className="text-white">Tutup</Button>
      </Modal.Footer>
    </Modal>
  );
}